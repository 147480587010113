import React, {
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes
} from 'react'
import cx from 'classnames'
import { IconProps } from '@toasttab/buffet-pui-icons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

export interface Item {
  header: React.ReactNode
  description: React.ReactNode
}
export interface ItemWithIcon extends Item {
  Icon: ForwardRefExoticComponent<IconProps & RefAttributes<HTMLElement>>
}

export interface InfoBlockProps extends ItemWithIcon {
  className?: string
}
const InfoBlock = ({
  Icon,
  header,
  description,
  className
}: InfoBlockProps) => {
  const screenSize = useScreenSize()
  const isMobile = screenSize <= ScreenSize.MD

  return (
    <div className={cx(className, 'flex flex-row px-2 xl:px-0')}>
      <div className='flex pr-3 lg:pr-8'>
        <Icon size={isMobile ? 'md' : 'lg'} accessibility='decorative' />
      </div>
      <div>
        <div className='pb-2 font-semibold text-default type-default'>
          {header}
        </div>

        <div className='text-secondary type-subhead'>{description}</div>
      </div>
    </div>
  )
}

export interface InfoBlockItems extends ItemWithIcon {
  child?: ReactNode
}
export interface PageInfoListProps {
  items: Array<InfoBlockItems>
  /** itemClassName is applied to each item */
  itemClassName?: string
}

export const PageInfoList = ({ items, itemClassName }: PageInfoListProps) => {
  const pageInfoList = (items || []).map((item) => (
    <li key={`section-${item.header}`}>
      {!!item.child ? (
        item.child
      ) : (
        <InfoBlock className={itemClassName} {...item} />
      )}
    </li>
  ))

  return (
    <ul
      data-testid='page-info-list'
      className='flex flex-col gap-y-6 lg:gap-y-8'
    >
      {pageInfoList}
    </ul>
  )
}

export interface PreviewInfoBlockProps extends Item {
  className?: string
}
const PreviewInfoBlock = ({
  header,
  description,
  className
}: PreviewInfoBlockProps) => {
  return (
    <div className={cx(className, 'flex flex-row xl:px-0 justify-center')}>
      <div className='text-center'>
        <p className='pt-6 pb-2 font-semibold text-secondary type-subhead'>
          {header}
        </p>
        <p className='text-secondary type-subhead mb-7'>{description}</p>
      </div>
    </div>
  )
}

export interface PreviewInfoBlockItems extends Item {
  child?: ReactNode
}
export interface PreviewPageInfoListProps {
  items: Array<PreviewInfoBlockItems>
  /** itemClassName is applied to each item */
  itemClassName?: string
}

export const PreviewPageInfoList = ({
  items,
  itemClassName
}: PreviewPageInfoListProps) => {
  const previewPageInfoList = (items || []).map((item) => (
    <li key={`section-${item.header}`}>
      <PreviewInfoBlock className={itemClassName} {...item} />
    </li>
  ))

  return <ul data-testid='preview-page-info-list'>{previewPageInfoList}</ul>
}
