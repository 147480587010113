import * as React from 'react'
import isEqual from 'fast-deep-equal'
import {
  useDropdownCommon,
  UseDropdownCommonProps,
  focusOnRefEl
} from '@toasttab/buffet-pui-floating-ui-base'
import { SelectItem, SelectOption } from '../types'
import { itemToValueFn, ItemToValueFn } from '../utils'
import { LabelProps } from '@toasttab/buffet-pui-text-base'
import { SelectButtonProps } from '@toasttab/buffet-pui-select-button'

export type UseSelectProps<
  TItem extends SelectItem<TValue>,
  TValue extends any = string
> = UseDropdownCommonProps & {
  options?: TItem[]
  itemToValue?: ItemToValueFn<TValue, TItem>
  value?: TValue
  onChange: (arg: TValue) => void
}

export const useSelect = <
  TValue,
  TItem extends SelectItem<any> = SelectOption<TValue>
>({
  placement,
  testId = 'select',
  options = [],
  itemToValue = itemToValueFn,
  isCircularKeyboardNav,
  value,
  onChange,
  id,
  enableSearch,
  matchReferenceWidth,
  hasLabel,
  ariaLabel,
  ariaLabelledBy,
  disabled,
  onOpenChange,
  onClose
}: UseSelectProps<TItem, TValue>) => {
  const selectedItem = React.useMemo(() => {
    const findOption = (val?: TValue | null) =>
      options.find((option: TItem) => isEqual(itemToValue(option), val))

    if (value === undefined) {
      return null
    }
    if (
      typeof value === 'object' &&
      itemToValue(value as unknown as TItem) === value
    ) {
      return value as unknown as TItem
    } else {
      return findOption(value) || null
    }
  }, [itemToValue, options, value])

  const selectedItemIndex = React.useMemo(() => {
    if (!selectedItem) {
      return null
    }
    return options.findIndex((option) =>
      typeof option === 'object'
        ? itemToValue(option) === value
        : (option as unknown as TValue) === value
    )
  }, [itemToValue, options, selectedItem, value])

  const {
    isOpen,
    setIsOpen,
    activeIndex,
    refs,
    floatingStyles,
    context,
    listRef,
    listContentRef,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    getLabelProps,
    elements,
    closeMenu
  } = useDropdownCommon<SelectButtonProps, LabelProps>({
    placement,
    testId,
    isCircularKeyboardNav,
    id,
    enableSearch,
    matchReferenceWidth,
    hasLabel,
    ariaLabel,
    ariaLabelledBy,
    disabled,
    onOpenChange,
    onClose,
    selectedIndex: selectedItemIndex
  })

  const handleSelect = React.useCallback(
    (item: TItem) => {
      onChange(itemToValue(item))
      setIsOpen(false)
      focusOnRefEl(elements.reference as HTMLElement)
    },
    [elements.reference, itemToValue, onChange, setIsOpen]
  )

  return {
    isOpen,
    setIsOpen,
    activeIndex,
    refs,
    floatingStyles,
    context,
    listRef,
    listContentRef,
    selectedItem,
    selectedItemIndex,
    handleSelect,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    getLabelProps,
    elements,
    closeMenu
  }
}
