import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek
} from '@toasttab/buffet-pui-date-utilities'

type WeekStartsOn = undefined | 0 | 1 | 2 | 3 | 4 | 5 | 6

const defines = {
  startOfWeek: (weekStartsOn: WeekStartsOn) =>
    startOfWeek(new Date(), { weekStartsOn }),
  endOfWeek: (weekStartsOn: WeekStartsOn) =>
    endOfWeek(new Date(), { weekStartsOn }),
  startOfLastWeek: (weekStartsOn: WeekStartsOn) =>
    startOfWeek(addDays(new Date(), -7), { weekStartsOn }),
  endOfLastWeek: (weekStartsOn: WeekStartsOn) =>
    endOfWeek(addDays(new Date(), -7), { weekStartsOn }),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
}

const rangeKeys = {
  today: 'today',
  yesterday: 'yesterday',
  thisWeek: 'this-week',
  lastWeek: 'last-week',
  thisMonth: 'this-month',
  lastMonth: 'last-month'
}

export const defaultRanges = (weekStartsOn: WeekStartsOn = 0) => [
  {
    label: rangeKeys.today,
    range: {
      from: defines.startOfToday,
      to: defines.endOfToday
    }
  },
  {
    label: rangeKeys.yesterday,
    range: {
      from: defines.startOfYesterday,
      to: defines.endOfYesterday
    }
  },

  {
    label: rangeKeys.thisWeek,
    range: {
      from: defines.startOfWeek(weekStartsOn),
      to: defines.endOfWeek(weekStartsOn)
    }
  },
  {
    label: rangeKeys.lastWeek,
    range: {
      from: defines.startOfLastWeek(weekStartsOn),
      to: defines.endOfLastWeek(weekStartsOn)
    }
  },
  {
    label: rangeKeys.thisMonth,
    range: {
      from: defines.startOfMonth,
      to: defines.endOfMonth
    }
  },
  {
    label: rangeKeys.lastMonth,
    range: {
      from: defines.startOfLastMonth,
      to: defines.endOfLastMonth
    }
  }
]
