import * as React from 'react'
import * as Yup from 'yup'

import { DateRange } from '@toasttab/buffet-pui-date-utilities'
import { DateInput } from '@toasttab/buffet-pui-text-input'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { ScreenSize } from '@toasttab/use-screen-size'
import { HelperText } from '@toasttab/buffet-pui-text-base'
import { useCustomRangeForm } from './useCustomRangeForm'
import { useDatePickerContext } from '../useDatePickerContext'
import { loadStrings, t } from '../defaultStrings'

const validationSchema = Yup.object()
  .shape({
    from: Yup.date()
      .typeError('Invalid start date')
      .required('Start date is required'),
    to: Yup.date().typeError('Invalid end date')
  })
  .test('range', (value) => {
    const { from, to } = value
    if (!from || !to) return true //this error will trigger only when both fields are set
    if (to < from) {
      return new Yup.ValidationError(
        'End date can not be earlier than start date',
        value,
        'range'
      )
    }
    return true
  })

const CustomRangeContainer: ({
  children,
  isModalView
}: {
  children: React.ReactElement
  isModalView: boolean
}) => JSX.Element = ({ children, isModalView }) => {
  const { modalParentSelector } = useDatePickerContext<DateRange>() // eslint-disable-line
  if (isModalView) {
    return (
      <Modal
        isOpen
        overflowBehavior='body' // eslint-disable-line
        parentSelector={modalParentSelector || undefined} // eslint-disable-line
      >
        <ModalHeader hideCloseIconForDesktop>{t('custom-date')}</ModalHeader>
        {children}
      </Modal>
    )
  }
  return children
}

interface CustomRangeFormProps {
  onChange?: (target: string, value: Date) => void
  closeMenu: () => void
}
export const CustomRangeForm = ({
  children,
  onChange,
  closeMenu
}: React.PropsWithChildren<CustomRangeFormProps>) => {
  const {
    locale,
    onSelect,
    screenSize,
    datePickerState,
    setDatePickerState,
    currentValue
  } = useDatePickerContext<DateRange>()

  // We store and manage the previous current value via a ref
  // This is so that onClose we can set the manual input value (the values for the custom range form) to
  // the previously selected values.
  const prevCurrentValue = React.useRef(currentValue)

  const manualInputValue = React.useMemo(() => {
    return datePickerState.manualInputValue
  }, [datePickerState.manualInputValue])

  const isSmallScreen = React.useMemo(
    () => screenSize < ScreenSize.SM,
    [screenSize]
  )

  const { values, errors, handleOnKeyUp, handleOnClick } = useCustomRangeForm({
    locale,
    initialValue: manualInputValue,
    validationSchema: validationSchema,
    onChange: (name, value) => {
      setDatePickerState((prev) => {
        return {
          ...prev,
          manualInputValue: {
            ...prev.manualInputValue,
            [name]: value
          }
        }
      })
      onChange && onChange(name, value)
    },
    onSubmit: (range) => {
      const _range = {
        from: range?.from,
        to: range?.to ?? range?.from
      }
      setDatePickerState((prev) => ({
        ...prev,
        manualInputValue: _range
      }))
      prevCurrentValue.current = _range
      onSelect?.(_range)
      closeHandler()
    }
  })

  const activeInputHandler = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const { name } = e.target as { name: keyof DateRange }
      setDatePickerState((prev) => {
        return {
          ...prev,
          activeInput: name !== prev.activeInput ? name : undefined
        }
      })
    },
    [setDatePickerState]
  )

  loadStrings()

  const Body = isSmallScreen ? ModalBody : React.Fragment

  const closeHandler = React.useCallback(() => {
    setDatePickerState((prev) => ({
      ...prev,
      enableManualInput: false,
      manualInputValue: prevCurrentValue.current as DateRange
    }))
    closeMenu()
  }, [setDatePickerState, closeMenu])

  return (
    <>
      <CustomRangeContainer isModalView={isSmallScreen}>
        <>
          <Body
            {...(isSmallScreen && {
              className: '!px-0',
              id: 'customRangeFormBody'
            })}
          >
            <div className='flex sm:flex-row sm:border-t justify-end p-4 md:p-6 py-3 relative'>
              <div className='w-full sm:w-auto'>
                <div className='flex space-x-2 items-start'>
                  <DateInput
                    name='from'
                    containerClassName='w-full md:w-auto'
                    label={t('start-date')}
                    locale={locale}
                    onKeyUp={handleOnKeyUp}
                    value={String(values.from)}
                    autoComplete='off'
                    invalid={Boolean(errors.from || errors.range)}
                    onFocus={activeInputHandler}
                    onBlur={activeInputHandler}
                    disablePrefix
                    inputMode='numeric'
                    type='tel'
                  />
                  <DateInput
                    name='to'
                    containerClassName='w-full md:w-auto'
                    label={t('end-date')}
                    locale={locale}
                    autoComplete='off'
                    onKeyUp={handleOnKeyUp}
                    value={String(values.to)}
                    invalid={Boolean(errors.to || errors.range)}
                    disablePrefix
                    onFocus={activeInputHandler}
                    onBlur={activeInputHandler}
                    inputMode='numeric'
                    type='tel'
                  />
                  {!isSmallScreen && (
                    <Button onClick={handleOnClick} className='mt-7'>
                      {t('apply')}
                    </Button>
                  )}
                </div>
                <div role='alert'>
                  <HelperText
                    helperText={errors.from || errors.to || errors.range}
                    invalid
                  />
                </div>
              </div>
            </div>
            {isSmallScreen && children}
          </Body>
          {isSmallScreen && (
            <ModalFooter>
              <Button
                className='flex-grow sm:flex-none'
                variant='link'
                onClick={closeHandler}
              >
                {t('cancel')}
              </Button>
              <Button
                className='flex-grow sm:flex-none'
                onClick={handleOnClick}
              >
                {t('apply')}
              </Button>
            </ModalFooter>
          )}
        </>
      </CustomRangeContainer>
    </>
  )
}
