import * as React from 'react'
import cx from 'classnames'
import { HelperText, Label } from '@toasttab/buffet-pui-text-base'
import { SelectButton } from '@toasttab/buffet-pui-select-button'
import {
  RenderToggleProps,
  SelectBaseProps,
  SelectItem,
  SelectOption
} from '../types'
import {
  itemToStringFn,
  itemToValueFn,
  ItemToValueFn,
  isSelectOption
} from '../utils'
import { useSelectSearch } from '../useSelectSearch'
import { SelectSearch } from '../SelectSearch'
import { SelectItemDefaultContent } from './SelectItemDefaultContent'
import { SelectListContainer } from '../SelectListContainer'
import { SelectListOptions } from './SelectListOptions'
import { useSelect } from '../useSelect'
import { t, loadStrings } from '../defaultStrings'

export type SelectProps<
  TValue = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
> = SelectBaseProps<TValue, TItem> & {
  value: TValue | undefined
  onChange: (arg: TValue) => void
  /** The autocomplete type of the select to help the browser autofill info (optional)
   *  See: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete */
  autoComplete?: string
  /** a function used to determine what the value of an object is. Use `itemToValue={item => item}` if you want to use a full option as the value*/
  itemToValue?: ItemToValueFn<TValue, TItem>
  /** Whether to show the subLabel in the selected state (button) */
  showSubLabelInButton?: boolean
  /** a prop to render a custom toggle (e.g. a button) */
  renderToggle?: (props: RenderToggleProps) => React.ReactNode
  /** An optional class to affect the border radius (e.g. use rounded-r-none to remove the radius on the right hand side) */
  borderRadiusClassName?: 'rounded-r-none' | 'rounded-l-none'
}

export const Select = <
  TValue extends any = string,
  TItem extends SelectItem<any> = SelectOption<TValue>
>({
  testId = 'select',
  size = 'auto',
  placement = 'bottom-end',
  placeholder = t('select'),
  itemToString = itemToStringFn,
  itemToValue = itemToValueFn,
  autoComplete,
  name,
  isCircularKeyboardNav,
  options = [],
  required = false,
  value,
  onChange,
  inlineBlock,
  containerClassName,
  label,
  helperIconButton,
  disabled,
  hideLabel,
  renderToggle,
  invalid,
  enableSearch,
  iconLeft,
  transparentBackground,
  borderRadiusClassName,
  showSubLabelInButton,
  searchPlaceholder,
  onSearch, // eslint-disable-line
  filterOptions,
  onSearchChange,
  searchAnyPartOfString,
  renderItem,
  truncateOption,
  additionalActions,
  errorText,
  helperText,
  preserveHelpSpace,
  id,
  onOpenChange,
  onClose,
  autoFocus,
  ...restProps
}: SelectProps<TValue, TItem>) => {
  const {
    isOpen,
    setIsOpen,
    activeIndex,
    refs,
    floatingStyles,
    context,
    listRef,
    listContentRef,
    handleSelect,
    testId: _testId,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    getLabelProps,
    selectedItem,
    selectedItemIndex,
    closeMenu
  } = useSelect({
    placement,
    testId,
    options,
    itemToValue,
    isCircularKeyboardNav,
    value,
    onChange,
    id,
    enableSearch,
    matchReferenceWidth: !inlineBlock,
    hasLabel: !!label,
    ariaLabel: restProps['aria-label'],
    ariaLabelledBy: restProps['aria-labelledby'],
    disabled,
    onOpenChange,
    onClose
  })

  const { filteredOptions, ...searchProps } = useSelectSearch({
    itemToString,
    options,
    onSearch,
    filterOptions,
    onSearchChange,
    searchAnyPartOfString
  })

  const selectedItemLabel = React.useMemo(() => {
    return itemToString(selectedItem)
  }, [selectedItem, itemToString])

  const selectedItemSubLabel = React.useMemo(() => {
    return isSelectOption(selectedItem)
      ? `${selectedItem.subLabel || ''}`
      : null
  }, [selectedItem])

  const toggleButtonIconLeft =
    isSelectOption(selectedItem) && selectedItem?.iconLeft
      ? selectedItem.iconLeft
      : iconLeft

  const commonToggleProps = {
    ...getReferenceProps(),
    'data-buffet-select': true
  }

  loadStrings()

  return (
    <div
      data-testid={testId}
      className={cx(
        'relative',
        {
          'inline-block': inlineBlock
        },
        containerClassName
      )}
    >
      <Label
        {...getLabelProps({
          useInInlineBlock: inlineBlock,
          helperIconButton: helperIconButton,
          required,
          className: hideLabel ? 'sr-only' : undefined
        })}
      >
        {label}
      </Label>

      {!!renderToggle ? (
        <div ref={refs.setReference}>
          {renderToggle({
            ...(commonToggleProps as unknown as RenderToggleProps)
          })}
        </div>
      ) : (
        <SelectButton
          testId={testId}
          invalid={invalid}
          isOpen={isOpen}
          disableFocusShadow={enableSearch && isOpen}
          size={size}
          selected={!!selectedItem}
          inlineBlock={inlineBlock}
          iconLeft={toggleButtonIconLeft}
          ref={refs.setReference}
          transparentBackground={transparentBackground}
          customHeight={!!selectedItemSubLabel}
          borderRadiusClassName={borderRadiusClassName}
          autoFocus={autoFocus}
          label={label}
          hideLabel={hideLabel}
          {...commonToggleProps}
        >
          {!!selectedItem ? (
            <SelectItemDefaultContent
              label={selectedItemLabel}
              subLabel={selectedItemSubLabel}
              isSelectButton
              showSubLabel={showSubLabelInButton}
            />
          ) : (
            placeholder
          )}
        </SelectButton>
      )}
      <SelectListContainer
        setFloatingRef={refs.setFloating}
        required={required}
        isOpen={isOpen}
        floatingStyles={floatingStyles}
        inlineBlock={inlineBlock}
        getFloatingProps={getFloatingProps}
        context={context}
        hasAdditionalActions={!!additionalActions}
        testId={`${testId}-panel`}
      >
        {enableSearch && (
          <SelectSearch<TValue, TItem>
            {...searchProps}
            searchPlaceholder={searchPlaceholder}
            setIsOpen={setIsOpen}
            onSearch={onSearch}
            isOpen={isOpen}
            activeIndex={activeIndex}
            options={filteredOptions}
            handleSelect={handleSelect}
            testId={`${testId}-search`}
            hasAdditionalActions={!!additionalActions}
          />
        )}
        <SelectListOptions
          testId={`${testId}-options`}
          highlightedIndex={activeIndex}
          selectedIndex={selectedItemIndex}
          options={filteredOptions}
          itemToString={itemToString}
          getItemProps={getItemProps}
          renderItem={renderItem}
          size={size}
          label={label}
          isOpen={isOpen}
          truncateOption={truncateOption}
          handleSelect={handleSelect}
          listRef={listRef}
          listContentRef={listContentRef}
          hasAdditionalActions={!!additionalActions}
          enableSearch={enableSearch}
        />
        {additionalActions && (
          <div className='px-3 py-2 border-t border-default'>
            {typeof additionalActions === 'function'
              ? additionalActions({ closeMenu })
              : additionalActions}
          </div>
        )}
      </SelectListContainer>
      {/* A hidden select field is used to allow browser autocomplete to work (example: address fields) */}
      {/* Note: Safari doesn't autofill hidden elements, so we hide our select using opacity and absolute position */}
      {autoComplete && (
        <>
          <select
            name={name}
            autoComplete={autoComplete}
            onChange={(event) => {
              const eventValue = event.target.value as TValue
              const selectedOption = options.find(
                (option: TItem) => itemToValue(option) === eventValue
              )
              if (selectedOption) {
                onChange(itemToValue(selectedOption))
              }
            }}
            aria-hidden
            className='absolute opacity-0 pointer-events-none'
            tabIndex={-1}
            defaultValue='DEFAULT'
          >
            <option disabled value='DEFAULT'>
              {t('none')}
            </option>
            {options.map((option) => {
              // The use of autoComplete requires that itemToValue return a string!
              const itemValue = itemToValue(option) as string
              return (
                <option key={itemValue as React.Key} value={itemValue}>
                  {itemToString(option)}
                </option>
              )
            })}
          </select>
        </>
      )}
      <HelperText
        testId={`${testId}-helper-text`}
        disabled={disabled}
        invalid={invalid}
        errorText={errorText}
        helperText={helperText}
        preserveHelpSpace={preserveHelpSpace}
      />
    </div>
  )
}
