import { SelectButtonProps } from '@toasttab/buffet-pui-select-button'
import { ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { StepperButton, StepperContainer } from '@toasttab/buffet-pui-text-base'
import * as React from 'react'
import {
  DatePickerValueType,
  useDatePickerContext
} from '../useDatePickerContext'
import { useDateRanges } from '../useDateRanges'
import { loadStrings, t } from '../defaultStrings'

export interface DateStepperContainerProps {
  value?: DatePickerValueType
  disabled?: boolean
  widthMode?: PresetWidthKey
  minDate?: Date
  maxDate?: Date
  showTooltips?: boolean
  size?: SelectButtonProps['size']
}

export enum PresetMinWidths {
  'range' = '22rem', // E.g. Fits "Apr 3, 2025 - Apr 11, 2025"
  'defined' = '21rem' // E.g. Fits "Last Week, Apr 3, 2025 - Apr 10, 2025", where the range is a small subtext
}
export type PresetWidthKey = keyof typeof PresetMinWidths | undefined

export const DateStepperContainer: React.FC<
  React.PropsWithChildren<DateStepperContainerProps>
> = ({
  value: selectedDate,
  disabled,
  widthMode,
  minDate,
  maxDate,
  size,
  showTooltips = true,
  children
}) => {
  const { onSelect } = useDatePickerContext()
  const { prev, next, prevDescription, nextDescription } = useDateRanges({
    dateValue: selectedDate,
    minDate,
    maxDate
  })
  loadStrings()
  const widthStyles = widthMode
    ? { style: { minWidth: PresetMinWidths[widthMode] } }
    : {}

  return (
    <StepperContainer {...widthStyles}>
      <StepperButton
        direction='prev'
        icon={<ChevronLeftIcon accessibility='decorative' />}
        label={prevDescription ?? t('previous')}
        tooltip={showTooltips ? prevDescription : null}
        disabled={!selectedDate || !prev || disabled} // Min/Max date settings can disable these
        disabledBorder={disabled}
        size={size}
        onClick={() => selectedDate && onSelect?.(prev)}
      />

      <div className={`grow`}>{children}</div>

      <StepperButton
        direction='next'
        icon={<ChevronRightIcon accessibility='decorative' />}
        label={nextDescription ?? t('next')}
        tooltip={showTooltips ? nextDescription : null}
        disabled={!selectedDate || !next || disabled} // Min/Max date settings can disable these
        disabledBorder={disabled}
        size={size}
        onClick={() => selectedDate && onSelect?.(next)}
      />
    </StepperContainer>
  )
}
